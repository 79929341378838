import "./stand-alone-child.scss"

import React from "react"

import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import loadable from "@loadable/component"

// import BannerCall from "../components/BannerCall"
// import Choices from "../components/for-you/Choices"
// import Layout from "../components/Layout"
// import Shelf from "../components/Shelf"
// import Slogan from "../components/Slogan"
import Layout from "../components/Layout"
import {
  getAllLocales,
  getCurrentLanguagePage,
  getGatsbyImage,
  getBackgroundImage,
} from "../plugins/common"

const BannerCall = loadable(() => import("../components/BannerCall"))
const Choices = loadable(() => import("../components/for-you/Choices"))
const Shelf = loadable(() => import("../components/Shelf"))
const Slogan = loadable(() => import("../components/Slogan"))

const standAloneChildHeaderRightEmpty =
  "../images/stand-alone-child/standAloneChildHeaderRightEmpty.webp"
const standAloneChildHeaderRightFull =
  "../images/stand-alone-child/standAloneChildHeaderRightFull.webp"
const standAloneChildShelfRightMint =
  "../images/stand-alone-child/standAloneChildShelfRightMint.webp"
const standAloneChildBannerRightEmpty =
  "../images/stand-alone-child/standAloneChildBannerRightEmpty.webp"
const standAloneChildBannerRightFull =
  "../images/stand-alone-child/standAloneChildBannerRightFull.webp"
const standAloneChildChoicesLeftFull =
  "../images/stand-alone-child/standAloneChildChoicesLeftFull.webp"

const StandAloneChildPage = ({ pageContext, location }) => {
  const { node: data, general, form } = pageContext
  const aleaToken = general.aleaToken
  const headerImage = getGatsbyImage(data.headerImage.image[0])
  const bannerImage = getGatsbyImage(data.banner.image[0])
  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const ogImage = getBackgroundImage(data.headerImage.image[0])
  //   console.log(data)

  const enableFooterForm = data.footerForm?.enableFooter
  const footerForm = {
    ...data.footerForm,
    ...form.freeQuote,
  }

  return (
    <div className="standAloneChild">
      <Layout
        props={pageContext.general}
        meta={data.meta}
        localizations={getAllLocales(data.localizations, data.locale)}
        location={location}
        lang={lang}
        ogImage={ogImage}
        hideNewsletter={true}
        token={aleaToken}
        footerForm={enableFooterForm ? footerForm : null}
      >
        <div className="overflow-hidden">
          <Slogan props={data.headerImage}>
            <div id="right" className="standAloneChildHeader position-relative">
              <div className="imageZoom d-unset">
                <GatsbyImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className="image imageAni"
                  image={headerImage}
                  alt="alea"
                />
              </div>
              <div className="">
                <StaticImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className="rightEmpty position-absolute"
                  src={standAloneChildHeaderRightEmpty}
                  alt=""
                  layout="constrained"
                />
                <StaticImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className="rightFull position-absolute"
                  src={standAloneChildHeaderRightFull}
                  alt=""
                  layout="constrained"
                />
              </div>
            </div>
          </Slogan>
          <div className="standAloneChildShelf">
            <div className=" position-relative">
              <StaticImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className="leftCopper position-absolute"
                src={standAloneChildShelfRightMint}
                alt=""
                layout="constrained"
              />
              <Shelf
                title=""
                list={data.description}
                startFrom="right"
                mobileStartFrom="text"
              />
            </div>
          </div>
          <BannerCall props={data.banner} bgColor="bg-daffodil">
            <div className="standAloneChildBanner position-relative">
              <StaticImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className="rightEmpty position-absolute"
                src={standAloneChildBannerRightEmpty}
                alt=""
                layout="constrained"
              />
              <StaticImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className="rightFull position-absolute"
                src={standAloneChildBannerRightFull}
                alt=""
                layout="constrained"
              />
              <div className="imageZoom d-unset">
                <GatsbyImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className="image imageAni position-absolute"
                  image={bannerImage}
                  alt="alea"
                />
              </div>
            </div>
          </BannerCall>

          <div className="standAloneChildChoices position-relative">
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className="leftFull position-absolute"
              src={standAloneChildChoicesLeftFull}
              alt=""
              layout="constrained"
            />
            <Choices
              props={data.products}
              dashColor="bg-copper"
              general={pageContext.general}
              lang={lang}
            />
          </div>
        </div>
      </Layout>
    </div>
  )
}
export default StandAloneChildPage
